<template>
    <div class="history-container">
        <v-card>
            <v-card-title>My History of Reviews</v-card-title>
            <v-data-table
                :headers="headers"
                :items="tableData"
                :loading="isLoading"
                :page-count="totalPages"
                :server-items-length="totalItems"
                :footer-props="{
                    itemsPerPageOptions: [ 10, 20, 50, 100]
                }"
                class="elevation-1"
                disable-sort
            >
                <template v-slot:[`item.ocrRequest.status`]="{ item }">
                    <v-chip
                        :color="statusColor(item.ocrRequest.status)"
                        label
                    >
                        {{ item.ocrRequest.status }}
                    </v-chip>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    <span>{{ formatDate(item.date) }}</span>
                </template>
                <template v-slot:[`item.ocrRequest`]="{ item }">
                    <v-img
                        :src="item.ocrRequest.imageUrl"
                        class="mx-auto"
                        height="120"
                        width="80"
                    />
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import reviewService from '@/services/review-service'
    import moment from 'moment'

    export default {
        data() {
            return {
                headers: [
                    {
                        text: 'Review ID',
                        align: 'center',
                        value: 'id'
                    },
                    {
                        text: 'Date',
                        align: 'center',
                        value: 'date'
                    },
                    {
                        text: 'Receipt Image',
                        align: 'center',
                        value: 'ocrRequest'
                    },
                    {
                        text: 'Status',
                        align: 'center',
                        value: 'ocrRequest.status'
                    },
                    {
                        text: 'Ocr Id',
                        align: 'center',
                        value: 'ocrRequest.id'
                    },
                    {
                        text: 'Ocr Client Id',
                        align: 'center',
                        value: 'ocrRequest.clientId'
                    }
                ],
                tableData: [],
                itemsPerPage: 10,
                currentPage: 1,
                totalPages: 0,
                totalItems: 0,
                isLoading: false,
                statusMap: {
                    MISSING_IMAGE: 'error',
                    MISSING_DATE: 'error',
                    MISSING_TAX_NUMBER: 'error',
                    MISSING_PRODUCTS: 'error',
                    OUT_OF_CAMPAIGN_PERIOD: 'error',
                    INVALID_TAX_NUMBER: 'error',
                    DUPLICATE_RECEIPT: 'error',
                    NO_TEXT: 'error',
                    PENDING: 'warning',
                    SUCCESS: 'success'
                }
            }
        },
        created() {
            this.fetchTableData()
        },
        methods: {
            async fetchTableData() {
                this.isLoading = true
                const requestParams = {
                    page: this.currentPage,
                    pageSize: this.itemsPerPage
                }

                const [data, error] = await this.universalTryCatch(reviewService.fetchData(requestParams))
                if (data) {
                    this.tableData = data.content
                    this.totalItems = data.totalElements
                    this.totalPages = data.totalPages
                }

                this.isLoading = false
                this.displayAlert(error)
            },
            formatDate(date) {
                return moment(date).format('DD/MM/YYYY, hh:mm:ss')
            },
            statusColor(status) {
                return this.statusMap[status]
            }
        }
    }
</script>

<style scoped>

</style>
