<template>
    <div class="panel">
        <v-card>
            <v-card-title>
                Requests
                <v-switch
                    v-model="autoReloadEnabled"
                    label="Auto Reload"
                    color="#1f2972"
                    class="mx-3"
                />
                <v-spacer />
                <v-btn
                    class="history-btn elevation-1"
                    color="#1f2972"
                    fab
                    small
                    @click="showHistory"
                >
                    <v-icon>
                        mdi-clipboard-text-clock-outline
                    </v-icon>
                </v-btn>
                <v-dialog
                    v-model="history"
                    max-width="1500px"
                >
                    <history />
                </v-dialog>
                <v-spacer />
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search with Client ID"
                    @keydown.enter="searchRequest"
                />
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="tableData"
                :loading="isLoading"
                :page-count="totalPages"
                :server-items-length="totalItems"
                :footer-props="{
                    itemsPerPageOptions: [ 10, 20, 50, 100]
                }"
                class="elevation-1"
                show-expand
                single-expand
                disable-sort
                @update:options="onOptionsUpdate"
                @dblclick:row="(item, slot) => slot.expand(!slot.isExpanded)"
            >
                <v-progress-linear indeterminate color="#1f2972">
                    <template v-slot:progress />
                </v-progress-linear>
                <template v-slot:top>
                    <v-dialog
                        v-model="dialog"
                        persistent
                        max-width="1500px"
                    >
                        <v-card height="100%" style="overflow: hidden;">
                            <v-row>
                                <v-col cols="6">
                                    <v-card-title>
                                        <span class="text-h5">Edit Receipt</span>
                                    </v-card-title>
                                </v-col>
                                <v-col cols="6 mt-5">
                                    <v-chip
                                        :color="statusColor(editedItem.status)"
                                        label
                                    >
                                        {{ editedItem.status }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-form
                                        ref="receiptForm"
                                        v-model="isFormValid"
                                        lazy-validation
                                    >
                                        <v-card-text>
                                            <v-container>
                                                <v-row
                                                    justify="center"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="8"
                                                    >
                                                        <v-autocomplete
                                                            v-model="editedItem.afm"
                                                            label="VAT Number"
                                                            :items="afms"
                                                            item-text="name"
                                                            item-value="id"
                                                            flat
                                                            small-chips
                                                            clearable
                                                        />
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    justify="center"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="8"
                                                    >
                                                        <v-datetime-picker
                                                            v-model="editedItem.receiptDate"
                                                            label="Receipt Date"
                                                            :time-picker-props="timeProps"
                                                        >
                                                            <template slot="dateIcon">
                                                                <v-icon>
                                                                    mdi-calendar
                                                                </v-icon>
                                                            </template>
                                                            <template slot="timeIcon" >
                                                                <v-icon>
                                                                    mdi-clock
                                                                </v-icon>
                                                            </template>
                                                        </v-datetime-picker>
                                                    </v-col>
                                                </v-row>
                                                <v-row
                                                    v-if="productList.length > 0"
                                                    justify="center"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="8"
                                                    >
                                                        <v-autocomplete
                                                            v-model="editedItem.productIds"
                                                            label="Products"
                                                            :items="productList"
                                                            item-text="name"
                                                            item-value="id"
                                                            flat
                                                            small-chips
                                                            multiple
                                                            clearable
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-form>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                            color="error"

                                            @click="close()"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="success"
                                            @click="save"
                                        >
                                            Save
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-col>

                                <v-col cols="6">
                                    <image-preview style="height: 100vh;" />
                                </v-col>
                            </v-row>
                            <v-dialog
                                v-model="isSureOpen"
                                max-width="200px"
                            >
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">Are you sure?</span>
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn
                                            color="error"
                                            @click="isSureOpen = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="success"
                                            @click="save"
                                        >
                                            Yes
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-card>
                    </v-dialog>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                        :color="statusColor(item.status)"
                        label
                    >
                        {{ item.status }}
                    </v-chip>
                </template>
                <template v-slot:[`item.receipt.hasWon`]="{ item }">
                    <v-chip
                        v-if="hasReceipt(item)"
                        :color="item.receipt.hasWon ? 'success' : 'error'"
                        label
                    >
                        {{ item.receipt.hasWon ? 'TRUE' : 'FALSE' }}
                    </v-chip>
                </template>
                <template v-slot:[`item.imageUrl`]="{ item }">
                    <v-img
                        v-if="item.imageUrl"
                        :src="item.imageUrl"
                        class="mx-auto"
                        :alt="item.imageUrl"
                        height="120"
                        width="80"
                    />
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                    <span>{{ formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:[`item.needsManualCheck`]="{ item }">
                    <img v-if="item.needsManualCheck" :src="require('@/assets/attention.svg')" alt="attention" class="attention-icon">
                </template>
                <template v-slot:[`item.receipt_check`]="{ item }">
                    <span>
                        {{ item.receiptCheck }}
                    </span>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length">
                        <v-container class="preview-container">
                            <div class="request-details">
                                <h2>Request {{ item.id }} Details</h2>
                                <span>
                                    <strong>Status:</strong> {{ item.status }}
                                </span>
                                <span>
                                    <strong>Client ID:</strong>
                                    {{ item.clientId }}
                                </span>
                                <span>
                                    <strong>Created At:</strong>
                                    {{ formatDate(item.createdAt) }}
                                </span>
                                <span>
                                    <strong>Webhook Url:</strong>
                                    {{ item.webhookUrl }}
                                </span>
                                <div v-if="hasReceipt(item)" class="receipt-details">
                                    <h3>Receipt {{ item.receipt.id }} Details</h3>
                                    <div class="receipt-details-info">
                                        <span>
                                            <strong>Has Won:</strong>
                                            {{ item.receipt.hasWon ? 'TRUE' : 'FALSE' }}
                                        </span>
                                        <span>
                                            <strong>Receipt VAT:</strong>
                                            {{ item.receipt.afm }}
                                        </span>
                                        <span>
                                            <strong>Receipt Date:</strong>
                                            {{ formatDate(item.receipt.receiptDate) }}
                                        </span>
                                        <div class="product-list">
                                            <h4>Products</h4>
                                            <span
                                                v-for="(product,index) in item.receipt.products"
                                                :key="index"
                                            >
                                                {{ product.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <v-img
                                v-if="item.imageUrl"
                                :src="item.imageUrl"
                                class="receipt-image-preview"
                                @click="openLightBox(item.imageUrl)"
                            />
                        </v-container>
                    </td>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        v-if="item.status !== 'NO_TEXT' && item.status !== 'DUPLICATE_RECEIPT'"
                        small
                        @click="editItem(item); openLightBox(item.imageUrl);"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import ocrService from '@/services/ocr-service'
    import productService from '@/services/product-service'
    import marketService from '@/services/market-service'
    import ImagePreview from '@/components/ImagePreview.vue'
    import moment from 'moment'
    import History from '@/components/History'
    export default {
        components: { ImagePreview, History },
        data() {
            return {
                timeProps: {
                    format: '24h'
                },
                autoReloadEnabled: true,
                reloadTimer: null,
                isLoading: false,
                search: '',
                dialog: false,
                history: false,
                isSureOpen: false,
                afms: [],
                headers: [
                    {
                        value: 'data-table-expand',
                        text: 'Request Details'
                    },
                    {
                        text: 'ID',
                        align: 'center',
                        value: 'id'
                    },
                    {
                        text: 'Status',
                        align: 'center',
                        value: 'status'
                    },
                    {
                        text: 'Client ID',
                        align: 'center',
                        value: 'clientId'
                    },
                    {
                        text: 'Created At',
                        align: 'center',
                        value: 'createdAt'
                    },
                    {
                        text: 'Has Won',
                        align: 'center',
                        value: 'receipt.hasWon'
                    },
                    {
                        text: 'Receipt Image',
                        align: 'center',
                        value: 'imageUrl'
                    },
                    {
                        text: 'Needs Manual Check',
                        align: 'center',
                        value: 'needsManualCheck'
                    },
                    {
                        text: 'Has Been Checked',
                        align: 'center',
                        value: 'receiptCheck'
                    },
                    {
                        text: 'Actions',
                        value: 'actions'
                    }
                ],
                tableData: [],
                itemsPerPage: 10,
                currentPage: 1,
                totalPages: 0,
                totalItems: 0,
                editedItemId: null,
                editedItem: {
                    afm: '',
                    receiptDate: null,
                    productIds: [],
                    status: ''
                },
                productList: [],
                isFormValid: true,
                receiptDateErrors: [],
                rules: {
                    required: value => !!value || 'Required.',
                    multipleRequired: value => (value && value.length > 0) || 'Required.'
                },
                statusMap: {
                    MISSING_IMAGE: 'error',
                    MISSING_DATE: 'error',
                    MISSING_TAX_NUMBER: 'error',
                    MISSING_PRODUCTS: 'error',
                    OUT_OF_CAMPAIGN_PERIOD: 'error',
                    INVALID_TAX_NUMBER: 'error',
                    DUPLICATE_RECEIPT: 'error',
                    NO_TEXT: 'error',
                    PENDING: 'warning',
                    SUCCESS: 'success'
                }
            }
        },
        watch: {
            search: {
                handler(newVal) {
                    if (newVal.length < 1) {
                        this.fetchTableData()
                    }
                }
            },
            dialog(val) {
                val || this.close()
            },
            editedItem: {
                handler(newVal) {
                    if (newVal?.afm?.length === 9) {
                        this.fetchProductList(newVal.afm)
                    }
                },
                deep: true
            },
            autoReloadEnabled: {
                immediate: true,
                handler(newVal) {
                    if (newVal) {
                        this.reloadTimer = setInterval(() => {
                            this.fetchTableData()
                        }, 30000)
                    } else {
                        clearInterval(this.reloadTimer)
                    }
                }
            }
        },
        created() {
            this.fetchTableData()
            this.fetchAfms()
        },
        beforeDestroy() {
            clearInterval(this.reloadTimer)
        },
        methods: {
            showHistory() {
                this.history = true
            },
            async fetchAfms() {
                const [data, error] = await this.universalTryCatch(marketService.fetchAfms())
                if (data) {
                    this.afms = data
                }

                this.displayAlert(error)
            },
            async fetchTableData() {
                this.isLoading = true
                const requestParams = {
                    page: this.currentPage,
                    pageSize: this.itemsPerPage
                }

                const [data, error] = await this.universalTryCatch(ocrService.fetchData(requestParams))
                if (data) {
                    this.tableData = data.content
                    this.totalItems = data.totalElements
                    this.totalPages = data.totalPages
                }

                this.isLoading = false
                this.displayAlert(error)
            },
            async searchRequest() {
                if (this.search.length === 0) {
                    return
                }
                this.isLoading = true
                const [data, error] = await this.universalTryCatch(ocrService.getRequestByClientId(this.search))

                if (data) {
                    this.tableData = [data]
                }
                this.isLoading = false
                this.displayAlert(error)
            },
            async fetchProductList(afm) {
                const [data, error] = await this.universalTryCatch(productService.fetchProducts(afm))

                if (data) {
                    this.productList = data
                }

                this.displayAlert(error)
            },
            onOptionsUpdate(options) {
                if (this.isLoading) return
                this.currentPage = options.page
                this.itemsPerPage = options.itemsPerPage

                this.fetchTableData()
            },
            async editItem(item) {
                const check = await this.checkEdit(item.id)
                let lock = false
                if (check) {
                    lock = await this.lockEdit(item.id)
                }
                this.editedItemId = item.id
                this.editedItem.status = item.status
                this.dialog = lock
            },
            close() {
                if (this.dialog && this.editedItemId) {
                    this.lockEdit(this.editedItemId)
                }
                this.dialog = false
                this.editedItemId = null
                this.productList = []
                this.resetForm()
                this.fetchTableData()
            },
            resetForm() {
                if (this.$refs.receiptForm) {
                    this.$refs.receiptForm.reset()
                }
            },
            async save() {
                if (!this.isSureOpen) {
                    this.isSureOpen = true
                    return
                }

                if (this.editedItem.receiptDate) {
                    this.editedItem.receiptDate = moment(this.editedItem.receiptDate).format()
                }

                const [, error] = await this.universalTryCatch(ocrService.updateReceipt(this.editedItemId, this.editedItem))
                this.displayAlert(error, 'Receipt updated successfully.')
                this.close()
            },
            formatDate(date) {
                return moment(date).format('DD/MM/YYYY, hh:mm:ss')
            },
            statusColor(status) {
                return this.statusMap[status]
            },
            hasReceipt(item) {
                return item.receipt
            },
            openLightBox(url) {
                this.$store.commit('setPhoto', url)
                // const routeData = this.$router.resolve({ name: 'ImagePreview', params: { url: url } })
                // window.open(routeData.href, '_blank')
                // this.$router.push({ name: 'ImagePreview', props: { url: url } })
            },
            getPhotoInEdit() {
                if (this.editedItemId) {
                    const item = this.tableData.find(tableItem => tableItem.id === this.editedItemId)
                    return item.imageUrl
                }
                return ''
            },
            async lockEdit(id) {
                const [data, error] = await this.universalTryCatch(ocrService.lockEdit(id))
                if (data !== null) {
                    return true
                } else {
                    this.displayAlert(error)
                    return false
                }
            },
            async checkEdit(id) {
                const [data, error] = await this.universalTryCatch(ocrService.checkEdit(id))
                if (data !== null) {
                    return true
                } else {
                    this.displayAlert(error)
                    return false
                }
            }
        }

    }
</script>

<style scoped>
.preview-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}

.receipt-image-preview {
    width: 100%;
    max-width: 300px;
}

.request-details {
    color: #1f2972;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.receipt-details,
.receipt-details-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.product-list {
    display: flex;
    flex-direction: column;
}

.product-list span::before {
    content: "\2022";
    font-weight: bold;
    font-size: 1rem;
    display: inline-block;
    width: 0.75rem;
}

.attention-icon {
    max-width: 50px;
}

.edit-image-preview {
    width: 100px;
    object-fit: scale-down;
    margin: 0 auto;
}

.history-btn {
  color: white;
}

.text-red {
    color: red;
}

</style>
