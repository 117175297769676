export default {
    state: {
        photo: null
    },
    mutations: {
        setPhoto(state, photo) {
            state.photo = photo
        },
        clearPhoto(state) {
            state.photo = null
        }
    },
    getters: {
        photo: state => state.photo
    }
}
