import request from '@/utils/request'

export default {
    login({ username, password }) {
        const data = {
            username,
            password
        }
        return request({
            url: '/users/login',
            method: 'post',
            data
        })
    }
}
