import jwtUtils from '@/utils/jwt-utils'
import router from '@/router'
export default {
    state: {
        jwtToken: localStorage.getItem('ocr-admin-jwt-token')
    },
    mutations: {
        setJwtToken(state, token) {
            state.jwtToken = token
        }
    },
    actions: {
        logout({ dispatch }) {
            dispatch('setJwtToken', null)
            router.push('/login')
        },
        setJwtToken({ commit }, token) {
            if (token) {
                commit('setJwtToken', token)
                localStorage.setItem('ocr-admin-jwt-token', token)
            } else {
                commit('setJwtToken', null)
                localStorage.removeItem('ocr-admin-jwt-token')
            }
        }
    },
    getters: {
        getCurrentUser: state => state.currentUser,
        isLoggedIn: state => state.jwtToken && jwtUtils.isValid(state.jwtToken),
        getJwtToken: state => state.jwtToken
    }
}
