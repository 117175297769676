<template>
    <div v-if="alert" class="alert-container">
        <v-alert
            class="custom-alert text-center"
            :value="!!alert"
            :type="alert.type"
            dismissible
            transition="scroll-x-reverse-transition"
            dense
        >
            {{ alert.message }}

        </v-alert>
    </div>
</template>

<script>
    export default {
        computed: {
            alert() {
                return this.$store.getters.alert
            }
        },
        watch: {
            alert(val) {
                if (val) {
                    setTimeout(() => {
                        this.clearAlert()
                    }, 5000)
                }
            }
        },
        methods: {
            clearAlert() {
                this.$store.commit('setAlert', null)
            }
        }
    }
</script>

<style scoped>

.alert-container {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 9999;
}

.custom-alert {
    max-width: 800px;
}
</style>
