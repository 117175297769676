export default {
    state: {
        alert: null
    },
    mutations: {
        setAlert(state, alert) {
            state.alert = alert
        }
    },
    getters: {
        alert: state => state.alert
    }
}
