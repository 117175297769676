import request from '@/utils/request'

export default {
    fetchData(filters) {
        const requestUrl = filters ? 'ocr/requests?' : 'ocr/requests'

        return request.get(requestUrl + new URLSearchParams(filters))
    },
    updateReceipt(requestId, data) {
        return request.post(`ocr/manual/request/${requestId}`, data)
    },
    getRequestByClientId(clientId) {
        return request.get(`ocr/request/client/${clientId}`)
    },
    lockEdit(requestId) {
        return request.post(`ocr/request/${requestId}/edit-toggle`)
    },
    checkEdit(requestId) {
        return request.post(`ocr/request/${requestId}/edit-check`)
    }
}
