import axios from 'axios'
import jwtUtils from '@/utils/jwt-utils'
import store from '@/store'
const API_URL = `${process.env.VUE_APP_HOST_DOMAIN}${process.env.VUE_APP_BASE_API}`

const service = axios.create({
    baseURL: API_URL,
    timeout: 2 * 60 * 1000
})

service.interceptors.request.use(
    config => {
        const apiToken = store.getters.getJwtToken
        if (jwtUtils.isValid(apiToken)) {
            config.headers.Authorization = `Bearer ${apiToken}`
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        if ([403, 401].includes(error.response.status)) {
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)

export default service
