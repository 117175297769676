import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Panel',
        props: true,
        component: () => import('@/views/Panel.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const isUserLoggedIn = store.getters.isLoggedIn
    if (!isUserLoggedIn && to.path !== '/login') {
        store.dispatch('logout').then(() => next({ path: '/login', query: { from: to.fullPath } }))
    } else if (to.path === '/login' && isUserLoggedIn) {
        next('/')
    } else {
        next()
    }
})

export default router
