<template>
    <div class="login-route">
        <v-form
            ref="loginForm"
            class="form"
            @submit.prevent="submit"
        >
            <v-container d-flex flex-column>
                <h2 class="text-center mb-3">Login</h2>
                <v-text-field
                    v-model.trim="user.username"
                    label="Username"
                    :rules="usernameRules"
                    required
                />
                <v-text-field
                    v-model.trim="user.password"
                    label="Password"
                    type="password"
                    :rules="passwordRules"
                    required
                />
                <v-btn
                    type="submit"
                    color="success"
                    class="mx-auto mt-3"
                >
                    Submit
                </v-btn>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import userService from '@/services/user-service'

    export default {
        data() {
            return {
                user: {
                    username: '',
                    password: ''
                },
                usernameRules: [v => !!v || 'Name is required',
                                v => (v && v.length >= 4) || 'Name must be less than 4 characters'],
                passwordRules: [v => !!v || 'Name is required',
                                v => (v && v.length >= 4) || 'Name must be less than 4 characters']
            }
        },
        methods: {
            submit() {
                if (!this.$refs.loginForm.validate()) {
                    return
                }

                userService.login(this.user).then((response) => {
                    this.$store.dispatch('setJwtToken', response.token)

                    this.$router.push(this.$route.query.from || '/')
                })
            }
        }
    }
</script>

<style scoped>
.login-route {
    width: 100%;
    height: calc(100vh - 64px);
    display: grid;
    place-items: center;
}

.form {
    transform: translateY(-50%);
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
}

</style>
