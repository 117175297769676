<template>
    <div class="viewer-wrapper mx-5 my-5">
        <viewer
            ref="viewer"
            :options="options"
            :images="images"
            class="viewer"
            @inited="inited"
        >
            <template #default="scope">
                <figure class="images">
                    <div v-for="source in scope.images" :key="source" class="image-wrapper">
                        <img
                            class="image"
                            :src="source"
                            :data-source="source"
                            :alt="source"
                        >
                    </div>
                </figure>
            </template>
        </viewer>
    </div>
</template>
<script>
    import Viewer from './Viewer'

    export default {
        components: {
            Viewer
        },
        data() {
            return {
                options: {
                    inline: true,
                    button: false,
                    navbar: false,
                    title: false,
                    toolbar: true,
                    tooltip: false,
                    movable: true,
                    zoomable: true,
                    rotatable: true,
                    scalable: false,
                    transition: false,
                    fullscreen: false,
                    keyboard: false,
                    url: 'data-source'
                }
            }
        },
        computed: {
            images() {
                return [this.$store.getters.photo]
            }
        },
        methods: {
            inited(viewer) {
                this.$viewer = viewer
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .viewer-wrapper {
    position: relative;
    height: 75vh !important;
    background: #333;
  }

  .viewer-container{
    height: 700px !important;
  }
  .methods {
    margin-bottom: 1em;
    flex-wrap: wrap;
    & > * {
      margin-right: 0.75rem;
    }
  }
  .options-panel {
    .panel-block {
      padding: 0;
      .checkbox {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0.5em 0.75em;
      }
    }
  }
  .viewer {
    height: 50vh;
    .images {
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px;
      .image-wrapper {
        display: inline-block;
        width: calc(33% - 20px);
        margin: 5px 5px 0 5px;
        .image {
          width: 100%;
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
</style>
