var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history-container"},[_c('v-card',[_c('v-card-title',[_vm._v("My History of Reviews")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.isLoading,"page-count":_vm.totalPages,"server-items-length":_vm.totalItems,"footer-props":{
                itemsPerPageOptions: [ 10, 20, 50, 100]
            },"disable-sort":""},scopedSlots:_vm._u([{key:"item.ocrRequest.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusColor(item.ocrRequest.status),"label":""}},[_vm._v(" "+_vm._s(item.ocrRequest.status)+" ")])]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:"item.ocrRequest",fn:function(ref){
            var item = ref.item;
return [_c('v-img',{staticClass:"mx-auto",attrs:{"src":item.ocrRequest.imageUrl,"height":"120","width":"80"}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }