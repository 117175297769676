<template>
    <v-app>
        <v-app-bar
            app
            flat
            height="90"
        >
            <v-container class="py-2 fill-height">
                <a href=".">
                    <v-img
                        alt="Logo"
                        class="shrink mr-2"
                        contain
                        :src="require('@/assets/logo.svg')"
                        transition="scale-transition"
                        width="150"
                    />
                </a>
                <v-btn
                    v-if="isLoggedIn"
                    class="logout-btn elevation-1"
                    color="error"
                    fab
                    @click="logout"
                >
                    <v-icon>
                        mdi-logout
                    </v-icon>
                </v-btn>
            </v-container>
        </v-app-bar>

        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>
        <alert />
    </v-app>
</template>

<script>
    import Alert from '@/components/Alert'
    export default {
        name: 'App',
        components: {
            Alert
        },
        data: () => ({
            toggleMenu: false
        }),
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
            }
        }
    }
</script>

<style scoped>
.app-title {
    color: #1f2972;
}

.container {
    position: relative;
}

.logout-btn {
    position: absolute !important;
    right: 1% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}
</style>
