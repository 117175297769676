export default {
    methods: {
        async universalTryCatch(promise) {
            try {
                const data = await promise
                return [data, null]
            } catch (error) {
                return [null, error]
            }
        },
        displayAlert(error, successMessage, errorMessage) {
            if (error) {
                errorMessage = errorMessage || error?.response?.data.message
                this.$store.commit('setAlert', {
                    type: 'error',
                    message: errorMessage
                })
            } else if (successMessage) {
                this.$store.commit('setAlert', {
                    type: 'success',
                    message: successMessage
                })
            }
        }
    }
}
