import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import configMixin from '@/mixins/config-mixin'
import requestMixin from '@/mixins/request-mixin'
import DatetimePicker from 'vuetify-datetime-picker'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.config.productionTip = false
VueViewer.setDefaults({
    zIndexInline: 2017,
    focus: false
})
Vue.use(DatetimePicker)
Vue.use(VueViewer, {
    debug: true
})
Vue.mixin(configMixin)
Vue.mixin(requestMixin)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
